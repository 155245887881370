

import {Options, Vue} from "vue-class-component";
import CreateChannel from "@/components/Flowbuilder/Sidebar/Create/CreateChannel.vue";
import CreateDecision from "@/components/Flowbuilder/Sidebar/Create/CreateDecision.vue";
import CreateLoop from "@/components/Flowbuilder/Sidebar/Create/CreateLoop.vue";
import ViewComponent from "@/flowbuilder/Builder/ViewComponent";

@Options({
    components: {CreateLoop, CreateDecision, CreateChannel},
    props: {
        apps: Array,
        collectedData: [],
        component: null,
        parentComponent: null,
        currentCollection: null,
        path: {},
    },
    data() {
        return {
            componentType: ''
        }
    },
    emits: ['saveComponent'],
    methods: {
        saveComponent(component: ViewComponent) {

            const e = new CustomEvent('addComponent', {
                detail: {
                    component: component,
                    parentComponent: this.$props.parentComponent,
                    currentCollection: this.$props.currentCollection,
                }
            });

            window.dispatchEvent(e);
        },
        setComponentType(type: string) {
            this.componentType = type;
        }
    }
})

export default class Create extends Vue{}

