import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "drop-container form-map" }
const _hoisted_2 = { class: "drop-down-options" }
const _hoisted_3 = { class: "mapping-source" }
const _hoisted_4 = { class: "mapping-mapping" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "form-control",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.displayDropDown && _ctx.displayDropDown(...args))),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.value) = $event))
    }, null, 512), [
      [_vModelText, this.value]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.collectedData, (propContainer, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(propContainer.source), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(propContainer.mapping, (propValue, propPath) => {
              return (_openBlock(), _createElementBlock("div", {
                key: propPath,
                onClick: ($event: any) => (_ctx.setPropPath(String(propPath)))
              }, _toDisplayString(propPath), 9, _hoisted_5))
            }), 128))
          ])
        ]))
      }), 128))
    ], 512), [
      [_vShow, _ctx.dropDownIsVisible]
    ])
  ]))
}