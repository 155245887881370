

import {Options, Vue} from "vue-class-component";
import Select from "@/components/Flowbuilder/Select.vue";

@Options({
    components: {Select},
    methods: {
        showOptions() {
            console.log('show')
        },
        updateWeekdays(weekdays: any) {
            console.log(weekdays)
        }
    },
    data() {
        return {
            time: null,
            selectedOption: null,
            selectOptions: [
                {
                    id: '1',
                    title: 'Monday'
                },
                {
                    id: '2',
                    title: 'Tuesday'
                },{
                    id: '3',
                    title: 'Wednesday'
                },
            ]
        }
    }
})

export default class ScheduleWeekDays extends Vue{}

