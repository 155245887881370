

import {Options, Vue} from "vue-class-component";

@Options({
    emits: ['closeModal'],
    methods: {
        closeModal() {
            this.$emit('closeModal')
        }
    }
})

export default class Modal extends Vue{}
