import AppAccount from "@/models/AppAccount";
import AppAccountConfig from "@/models/AppAccountConfig";

export default class AppAccountService {
    createFromResponse(response: any): AppAccount {

        const configuration: Array<AppAccountConfig> = [];

        response.configuration.forEach((config: any) => {
            configuration.push(
                new AppAccountConfig(config.id, config.key, config.value, config.created)
            )
        });

        return new AppAccount(
            response.id,
            response.appName,
            response.name,
            response.userId,
            response.created,
            configuration
        );
    }
}
