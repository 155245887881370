
import ApiRequest from "@/api/ApiRequest";
import {Options, Vue} from "vue-class-component";

@Options({
    data() {
        return {
            passwordIsHidden: true,
            email: '',
            companyName: '',
            password: '',
            agreeTerms: false,
            errors: [],
            registrationSuccess: false
        }
    },
    methods: {
        showTermsAndConditions(): void {
            console.log('show')
        },
        register() {
            this.errors = [];

            if (this.companyName === '') {
                this.errors.push(this.$t('user.registration.error.company'))
            }

            if (this.email === '') {
                this.errors.push(this.$t('user.registration.error.email'))
            }

            if (this.password === '') {
                this.errors.push(this.$t('user.registration.error.password'))
            }

            if (this.password !== '' && this.password.length < 8) {
                this.errors.push(this.$t('user.registration.error.passwordLength'))
            }

            if (this.agreeTerms === false) {
                this.errors.push(this.$t('user.registration.error.agreeTerms'))
            }

            if (this.errors.length > 0) {
                return;
            }

            ApiRequest('POST', '/account', {
                companyName: this.companyName,
                email: this.email,
                password: this.password,
                agreeTerms: this.agreeTerms ? '1' : '0',
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                country: 'en'
            }).then((response) => {
                if (response.error) {
                    for (let i in response.messages) {
                        this.errors.push(response.messages[i]);
                    }

                    return;
                }

                this.registrationSuccess = true;
            });
        },
        toggleShowPassword() {
            this.passwordIsHidden = !this.passwordIsHidden
        }
    },
    mounted() {
        this.$refs.inputEmail.focus();
    }
})


export default class Register extends Vue{}
