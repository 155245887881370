

import {Options, Vue} from "vue-class-component";
import Select from "@/components/Flowbuilder/Select.vue";
import ChannelComponent from "@/flowbuilder/Builder/Components/ChannelComponent";

@Options({
    components: {Select},
    props: {
        actions: [],
        component: null
    },
    emits: ['operationIdSelected'],
    data() {
        return {
            selectedApp: null,
            appAccounts: [],
            selectActionOptions: [],
            selectedOption: {},
            operationId: '',
        }
    },
    methods: {
        setOperationId(operationId: string) {
            this.$emit('operationIdSelected', operationId)
        }
    },
    mounted() {
        if (typeof this.$props.component !== 'undefined') {
            const channel : ChannelComponent = this.$props.component as ChannelComponent;
            this.selectedOption = {
                id: channel.getAction(),
                title: channel.getAction(),
            }
        }
    }
})

export default class OptionAction extends Vue{}
