

import {Options, Vue} from "vue-class-component";

@Options({
    props: {
        component: Object
    },
    data() {
        return {
            trigger: {
                type: 'manual'
            }
        }
    },
    emits: [
        "triggerUpdate"
    ],
    methods: {
        save() {
            console.log('emit manual')
            this.$emit('triggerUpdate', this.trigger);
        }
    }
})

export default class Manual extends Vue {}

