

import {Options, Vue} from "vue-class-component";

@Options({
    props: {
        id: String
    }
})
export default class Endpoint extends Vue {

}
