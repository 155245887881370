import Component from "@/flowbuilder/Builder/Components/Component";

export default class TriggerComponent extends Component
{
    type = '';

    parameters: any = {};

    constructor(
        type: string,
        parameters: any
    ) {
        super();
        this.type = type;
        this.parameters = parameters;
    }

    getType(): string
    {
        return this.type;
    }

    setType(type: string): void
    {
        this.type = type;
    }

    getParameters(): any
    {
        return this.parameters;
    }

    setParameters(parameters: any): void
    {
        this.parameters = parameters;
    }
}
