

import ApiRequest from "@/api/ApiRequest";
import {Options, Vue} from "vue-class-component";
@Options({
    async mounted() {
        this.fetchApps();
        (document.getElementById('app-Account-name') as HTMLElement).focus();
    },
    data() {
        return {
            apps: [],
            selectedApp: {},
            configuration: [],
            authSuccess: false,
            title: '',
            id: '',
            account: {}
        }
    },
    methods: {
        fetchApps() {
            this.$root.ProgressBarStart();
            ApiRequest('GET', '/app').then(response => {
                this.apps = response;
                this.$root.ProgressBarFinalize();
                this.loaded = true;
            })

        },
        configure() {
            let data = {
                appName: this.selectedApp.appName,
                name: this.title,
                configuration: [],
            };

            if (this.selectedApp.appName === '' || this.title === '') {
                console.log('appName or appAccountName can not be empty')
                return;
            }

            ApiRequest('POST', '/app/account', data).then((response) => {
                if (typeof response.id === 'undefined') {
                    console.log('could not create app Account');
                    return;
                }

                this.account = response;
                this.auth(this.selectedApp);
            });
        },
        triggerSave(event: any) {
            if (event.keyCode === 13) {
                this.setTitle();
            }
        },
        setApp(app: any) {
            this.selectedApp = app;

            let appContainers = document.getElementsByClassName('app-list-item');

            for (let i in appContainers) {
                if (typeof appContainers[i].getAttribute !== 'function') {
                    continue;
                }
                if (appContainers[i].getAttribute('data-appName') !== app.appName) {
                    appContainers[i].className = 'app-list-item';
                } else {
                    appContainers[i].className = 'app-list-item active';
                }
            }
        },
        setTitle() {
            this.title = (document.getElementById('app-Account-name') as HTMLInputElement).value;
        },
        auth(app: any) {

            if (typeof this.account.id === 'undefined') {
                console.log('Something went wrong in Account creation');
                return;
            }

            let authType = Object.keys(this.selectedApp.authType)[0];

            if (authType.toLowerCase() === 'oauth2') {
                if (app.authType[authType]['x-client']) {

                    ApiRequest(
                        'GET', '/google/auth/url?appAccountId=' + this.account.id
                    ).then((response) => {

                        window.open(
                            response.url,
                            'targetWindow',
                            `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=800,
                                    height=600`);

                    });
                }
            }

            if (authType.toLowerCase() === 'basicauth') {
                console.log('load basic auth')
            }
        }
    }
})

export default class AppCreate extends Vue{}

