export default class App {

    private readonly appName: string;

    private readonly image: string;

    constructor(appName: string, image: string) {
        this.appName = appName;
        this.image = image;
    }

    getImage(): string
    {
        return this.image;
    }

    getName(): string
    {
        return this.appName;
    }
}