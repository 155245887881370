

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import {useRoute} from "vue-router";

@Options({

    data() {
        return {
            appAccount: null,
            app: null
        }
    },
    methods: {
        beautify(label: string): string {
            label = label.replace('_', ' ')
            return label;
        },
        save() {
            ApiRequest('POST', '/app/account/' + this.appAccount.id, this.appAccount).then(() => {
                console.log('app account saved')
            });
        }
    },
    mounted() {

        const route = useRoute()

        ApiRequest('GET', '/app/account/' + route.params.id).then((response) => {
            this.appAccount = response;
            this.$root.header = 'Account: ' + this.appAccount.name

            ApiRequest('GET', '/app?filter=' + this.appAccount.appName).then((response) => {
                this.app = response;
            });
        });
    },
    beforeMount() {
        this.$root.header = ''
    }
})

export default class AppAccountEdit extends  Vue{}

