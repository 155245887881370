

import {Options, Vue} from "vue-class-component";

import OptionAccount from "@/components/Flowbuilder/Sidebar/ChannelOption/OptionAccount.vue";
import OptionMapping from "@/components/Flowbuilder/Sidebar/ChannelOption/OptionMapping.vue";
import OptionAction from "@/components/Flowbuilder/Sidebar/ChannelOption/OptionAction.vue";
import Mapping from "@/flowbuilder/Mapping/Mapping";
import App from "@/flowbuilder/Builder/App";
import AppAccount from "@/models/AppAccount";
import AppAccountService from "@/service/AppAccountService";
import CreateChannelToolbar from "@/components/Flowbuilder/Sidebar/Create/CreateChannelToolbar.vue";
import OptionParameters from "@/components/Flowbuilder/Sidebar/ChannelOption/OptionParameters.vue";
import ChannelComponent from "@/flowbuilder/Builder/Components/ChannelComponent";
import {ComponentType} from "@/flowbuilder/Builder/Enum/ComponentType";
import ViewComponent from "@/flowbuilder/Builder/ViewComponent";

@Options({
    props: {
        apps: Array,
        responseMap: Array,
        collectedData: Array,
        parentComponent: {},
        path: {},
        component: null
    },
    emits: ['saveComponent'],
    data() {
        return {
            // holds the action from the yaml file
            actions: [],
            app: {},
            appAccount: null,
            requestSchema: {},
            parameters: [],
            operationId: null,
            mapping: {},
            currentCarouselIndex: 0,
            isEditing: false,
            tabs: {
                account: {
                    open: true
                },
                action: {
                    open: false
                },
                mapping: {
                    open: false
                },
                parameters: {
                    open: false
                }
            }
        }
    },
    components: {OptionParameters, CreateChannelToolbar, OptionAction, OptionMapping, OptionAccount},
    beforeMount() {
        if (typeof this.$props.component !== 'undefined') {
            const channel : ChannelComponent = this.$props.component as ChannelComponent;
            this.mapping = channel.getMapping();
            this.parameters = channel.getParameters();
        }
    },
    mounted() {
        if (typeof this.$props.component !== 'undefined') {

            const appAccountService: AppAccountService = new AppAccountService();
            const channel : ChannelComponent = this.$props.component as ChannelComponent;

            this.appAccountSelected(
                appAccountService.createFromResponse(channel.getAppAccount())
            );

            this.operationIdSelected(
                channel.getAction()
            )
        }

        this.isEditing = true;
        this.currentCarouselIndex = -1;
        this.move(0);
    },
    methods: {
        move(index: number) {

            if (index === this.currentCarouselIndex || !this.isEditing) {
                return;
            }

            const carouselItems = document.getElementsByClassName('carousel-item');

            for (let i = 0; i < carouselItems.length; i++) {
                const carouselItem = carouselItems[i] as HTMLElement;
                carouselItem.style.display = i === index ? 'block' : 'none';
            }

            this.currentCarouselIndex = index;
        },
        next() {

            const itemCount = document.getElementsByClassName('carousel-item').length;

            if (this.currentCarouselIndex < itemCount  - 1) {
                this.move(this.currentCarouselIndex + 1);
            }
        },
        prev() {
            if (this.currentCarouselIndex >= 1) {
                this.move(this.currentCarouselIndex - 1);
            }
        },
        saveParameters(parameters: any[]): void
        {
            this.parameters = parameters;
            this.move(3);
        },
        toggleDisplayState(tab: string) {
            this.tabs[tab].open = !this.tabs[tab].open;
        },
        saveComponent() {

            const channel : ChannelComponent = this.$props.component as ChannelComponent;

            channel.setApp(new App(this.app.appName, this.app.image));

            this.appAccount.configuration.forEach((config: any, index:number) => {
                this.appAccount.configuration[index] = {
                    key: config.key,
                    id: config.id,
                    value: config.value,
                }
            });

            channel.setMapping(this.mapping);
            channel.setAction(this.operationId);
            channel.setAppAccount(this.appAccount);
            channel.setParameters(this.parameters);

            const e = new CustomEvent('updateComponent', {
                detail: {
                    component: channel,
                    parentComponent: this.$props.parentComponent,
                    currentCollection: this.$props.currentCollection,
                    path: this.$props.parth,
                }
            });
            window.dispatchEvent(e);
        },
        createComponent() {

            const params: any = {};

            this.parameters.forEach((param: any) => {
                params[param.name] = param.value;
            })

            this.appAccount.configuration.forEach((config: any, index:number) => {
                this.appAccount.configuration[index] = {
                    key: config.key,
                    id: config.id,
                    value: config.value,
                }
            });

            const component : ChannelComponent = new ChannelComponent(
                null,
                new App(this.app.appName, this.app.image),
                this.operationId,
                this.appAccount,
                this.mapping,
                params,
                ''
            )

            const view : ViewComponent = new ViewComponent(ComponentType.CHANNEL, null, component);

            this.$emit('saveComponent', view);
        },
        appAccountSelected(appAccount: AppAccount): void {

            this.appAccount = appAccount;

            this.$props.apps.forEach((app: any) => {
                if (app.appName === appAccount.getAppName()) {

                    this.app = app;
                    const collection: any = [];

                    app.actions.forEach((action: any) => {
                        collection.push({
                            id: action.operationId,
                            title: typeof action.description !== 'undefined' ? action.description : action.operationId,
                        })
                    })

                    this.actions = collection;
                    this.move(1);
                }
            })
        },
        operationIdSelected(operationId: string): void
        {
            this.operationId = operationId;

            let channel : ChannelComponent | null = null;

            if (this.$props.component !== null && typeof this.$props.component !== 'undefined') {
                channel = this.$props.component as ChannelComponent;
            }

            if (channel !== null) {
                channel.setAction(operationId);
            }

            if (this.$props.component !== null && typeof this.$props.component !== 'undefined') {
                const channel : ChannelComponent = this.$props.component as ChannelComponent;
                channel.setAction(operationId);
            }

            this.app.actions.forEach((action: any) => {
                if (action.operationId === operationId) {

                    if (typeof action.parameters === 'undefined' || action.parameters.length === 0) {
                        console.log('no parameters present for ' + operationId)
                    } else {

                        let parameters: any[] = [];
                        this.parameters = [];

                        if (channel !== null) {
                            parameters = channel.getParameters();
                        }

                        action.parameters.forEach((param: any) => {

                            let value = '';

                            for (const i in parameters) {
                                if (parameters[i].name === param.name) {
                                    value = parameters[i].value;
                                }
                            }

                            this.parameters.push({
                                name: param.name,
                                value: value
                            })
                        })
                    }

                    if (typeof action.requestBody === 'undefined' || action.requestBody.content.length === 0) {
                        console.log('no schema present for ' + operationId)
                        this.move(2);
                        return;
                    }

                    const requestContentTypeKey = Object.keys(action.requestBody.content)[0];

                    if (action.requestBody.content[requestContentTypeKey].length === 0) {
                        this.move(2);
                        return;
                    }

                    const mapping: Mapping = new Mapping()

                    const x = mapping.recursiveVars(
                        action.requestBody.content[requestContentTypeKey].schema
                    );

                    this.requestSchema = mapping.flatten(x);
                    this.move(2);
                }
            })
        }
    }
})

export default class CreateChannel extends Vue{}

