import Component from "@/flowbuilder/Builder/Components/Component";

export default class DecisionComponent extends Component
{
    id: string | null;
    rules: any = {};

    laneTrue: any[] = [];
    laneFalse: any[] = [];

    constructor(id: string | null, rules: any, laneTrue: any[], laneFalse: any[]) {
        super('');
        this.id = id;
        this.rules = rules;
        this.laneTrue = laneTrue;
        this.laneFalse = laneFalse;
    }

    getId(): string | null
    {
        return this.id;
    }

    getRules(): any
    {
        return this.rules;
    }

    setRules(rules: any): void
    {
        this.rules = rules;
    }

    getLaneTrue(): any[]
    {
        return this.laneTrue;
    }

    getLaneFalse(): any[]
    {
        return this.laneFalse;
    }
}
