

import { Options, Vue } from "vue-class-component";

@Options({
    components: { },
    props: {
        id: String,
        component: Object
    }
})
export default class Decision extends Vue {}
