
import {Options, Vue} from "vue-class-component";
import Select from "@/components/Flowbuilder/Select.vue";

@Options({
    components: {
        Select
    },
    emits: ['updateSchedule'],
    methods: {
        setIntervalAmount(value: any) {
            this.schedule.interval.amount = value;
            this.$emit('updateSchedule', this.schedule);
        },
        setIntervalUnit(value: any) {
            this.schedule.interval.unit = value;
            this.$emit('updateSchedule', this.schedule);
        }
    },
    data() {
        return {
            selectedOption: null,
            schedule: {
                type: 'regular_interval',
                interval: {
                    amount: 0,
                    unit: ''
                }
            },
            intervalTypeOptions: [
                {
                    id: 'minutes',
                    title: 'minutes',
                    description: 'Minutes'
                },{
                    id: 'hours',
                    title: 'hours',
                    description: 'Hours'
                }
            ],
            intervalUnitOptions: [
                {
                    id: '1',
                    title: '1'
                },{
                    id: '2',
                    title: '2'
                },{
                    id: '3',
                    title: '3'
                },{
                    id: '4',
                    title: '4'
                }
            ]
        }
    }
})

export default class ScheduleRegularInterval extends Vue{}
