


import {Options, Vue} from "vue-class-component";

@Options({
    emits: ['mappingSelected'],
    props: {
        value: String,
        collectedData: Array
    },
    mounted() {
        if (this.$props.value !== '') {
            this.value = this.$props.value;
        }
    },
    data() {
        return {
            value: '',
            dropDownIsVisible: false,
            propertyPath: ''
        }
    },
    methods: {
        displayDropDown() {
            this.dropDownIsVisible = !this.dropDownIsVisible;
        },
        setPropPath(propPath: string) {
            this.$emit('mappingSelected', propPath)
            this.value = propPath;
            this.dropDownIsVisible = false;
        }
    }

})

export default class DropDownSearch extends Vue{}
