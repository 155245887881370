

import {Options, Vue} from "vue-class-component";
import MappingTree from "@/components/Flowbuilder/MappingTree/MappingTree.vue";

@Options({
    components: {MappingTree},
    emits: ['saveParameters'],
    props: {
        parameters: Array,
        collectedData: Array
    },
    data() {
        return {
            parameters: []
        }
    },
    methods: {
        keyup(value: string, id: string) {
            console.log(id);
            console.log(value);
            const pid: string  = id.replace('param_', '')

            this.$props.parameters.forEach((param: any) => {
                if (param.name === pid) {
                    param.value = value;
                }
            });
        },
        saveParameters() : void
        {
            const params: any[] = [];

            this.$props.parameters.forEach((param: any) => {
                params[param.name] = param.value;
            })

            this.$emit('saveParameters', params)
        },
        pathSelected(path: string, id: string) {
            const pid: string  = id.replace('param_', '')

            this.$props.parameters.forEach((param: any) => {
                if (param.name === pid) {
                    param.value = path;
                }
            });
        }
    }
})


export default class OptionParameters extends Vue{}
