import Component from "@/flowbuilder/Builder/Components/Component";
import App from "@/flowbuilder/Builder/App";
import AppAccount from "@/models/AppAccount";

export default class ChannelComponent extends Component
{
    id: string | null;

    app : App;

    action = '';

    appAccount: any;

    mapping: any = {};

    parameters: any[] = [];

    constructor(
        id: string | null,
        app: App,
        action: string,
        appAccount: any,
        mapping: any,
        parameters: any,
        componentReferenceId: string
    ) {
        super(componentReferenceId);
        this.id = id;
        this.action = action;
        this.appAccount = appAccount;
        this.mapping = mapping;
        this.parameters = parameters;
        this.app = app;
    }

    getId(): string | null
    {
        return this.id;
    }

    getApp(): App
    {
        return this.app;
    }

    setApp(app: App): void
    {
        this.app = app;
    }

    getAction(): string
    {
        return this.action;
    }

    setAction(action: string)
    {
        this.action = action;
    }

    getAppAccount(): any
    {
        return this.appAccount;
    }

    setAppAccount(account: AppAccount): void
    {
        this.appAccount = account;
    }

    getParameters(): any
    {
        return this.parameters;
    }

    setParameters(parameters: any[]): void
    {
        this.parameters = parameters;
    }

    getMapping(): any
    {
        return this.mapping;
    }

    setMapping(mapping: any): void
    {
        this.mapping = mapping;
    }
}