import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-container" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        value: this.$props.value,
        type: "text",
        class: "form-control search-box",
        onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (this.search && this.search(...args)))
      }, null, 40, _hoisted_2),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleVisibility && _ctx.toggleVisibility(...args))),
        class: _normalizeClass('map-button ' + (!this.$props.displayButton ? 'hide' : '') + ' '  + (this.visible ? 'active' : ''))
      }, "map", 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass('mapping-tree ' + (this.visible ? 'active' : 'hide')),
      id: this.$props.id
    }, null, 10, _hoisted_3)
  ], 64))
}