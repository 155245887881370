export default class UserToken
{
    constructor(
        private token: string,
        private expiresAt: number
    ) {
    }

    getToken(): string
    {
        return this.token;
    }

    getExpiresAt(): number
    {
        return this.expiresAt;
    }
}