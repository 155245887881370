import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "option-account tile-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 0,
  class: "fullwidth app-account-select"
}
const _hoisted_5 = {
  key: 1,
  class: "fullwidth create-account-container"
}
const _hoisted_6 = {
  key: 2,
  class: "button-container"
}
const _hoisted_7 = { class: "or" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_CreateAccount = _resolveComponent("CreateAccount")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.apps, (app, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass('tile ' + (_ctx.selectedApp !== null && _ctx.selectedApp.getName() === app.appName ? 'selected' : '')),
        key: index,
        onClick: ($event: any) => (_ctx.setApp(app.appName, app.image))
      }, [
        _createElementVNode("img", {
          src: app.image,
          alt: app.title
        }, null, 8, _hoisted_3),
        _createElementVNode("span", null, _toDisplayString(app.title), 1)
      ], 10, _hoisted_2))
    }), 128)),
    (_ctx.selectedApp !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Select, {
            id: 'app_account',
            ref: "app_account",
            title: 'Select an existing account',
            options: _ctx.selectAccountOptions,
            selected: _ctx.selectedOption,
            onOptionSelected: _ctx.setExistingAccount
          }, null, 8, ["options", "selected", "onOptionSelected"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.createNewAccount && this.selectedApp !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_CreateAccount, {
            appName: this.selectedApp.appName,
            onCancelCreateAppAccount: _ctx.cancelCreateAccount,
            onAppAccountCreated: _ctx.appAccountCreated
          }, null, 8, ["appName", "onCancelCreateAppAccount", "onAppAccountCreated"])
        ]))
      : _createCommentVNode("", true),
    (this.selectedApp !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "action",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setAppAccount && _ctx.setAppAccount(...args)))
          }, "Use this account"),
          _createElementVNode("div", _hoisted_7, [
            _createTextVNode(" or "),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.setCreateNewAccount(true);})
            }, "create a new account")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}