import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "drop-container form-map" }
const _hoisted_3 = { class: "mapping-dropdown" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "mapping-select-container" }
const _hoisted_6 = ["data-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "search-option-container",
    id: 'search-option-'+this.$props.id
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          type: "text",
          onKeyup: _cache[0] || (_cache[0] = ($event: any) => (this.textSearch($event))),
          placeholder: "Type to search",
          autocomplete: "off",
          class: "form-control",
          value: typeof this.$props.selectedOption !== 'undefined' && this.$props.selectedOption !== null ? this.$props.selectedOption.title : ''
        }, null, 40, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.options, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              "data-id": option.id,
              class: "mapping-option",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setOption($event)))
            }, _toDisplayString(option.title), 9, _hoisted_6))
          }), 128))
        ])
      ])
    ])
  ], 8, _hoisted_1))
}