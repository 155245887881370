

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";

    @Options({
        data() {
            return {
                user: null,
                products: Array,
                recurringType: '',
                plan: '',
                productSelected: null
            }
        },
        methods: {
            regularPrice(price: string): string
            {
                const annualPrice = parseInt(price) * 12;
                return (annualPrice ).toFixed(0) + ',-'
            },
            discountPrice(price: string): string
            {
                const perc = 6;
                const annualPrice = parseInt(price) * 12;
                const discount = (annualPrice / 100) * perc;

                return (annualPrice - discount).toFixed(0) + ',-'
            },

            switchRecurringType(type: string): void {
                this.recurringType = type;
            },
            setProduct(product: any): void
            {
                this.productSelected = product;
            }
        },
        mounted() {

            this.$root.header = 'Upgrade your account'
            this.$root.activeMenu = '';


            let localUser = localStorage.getItem('user');

            if (localUser !== null) {
                this.user = JSON.parse(localUser);
            }

            ApiRequest('GET', '/product').then ((response) => {
                this.products = response;
                this.loaded = true;
                this.plan = this.user.subscription.plan;
                this.recurringType = this.user.company.subscriptionSettings.recurring;
            });
        }
    })

export default class Upgrade extends Vue{}

