<template>
    <div class="flex-container">
        <div class="panel">
            <div class="image">
                <img src="/svg/book.svg" width="175">
            </div>
            <div class="text">

                <h3>{{ $t("page.apps-add.title") }}</h3>
                <div>
                    {{ $t("page.apps-add.body") }}
                </div>
                <div>
                    <button class="btn btn-default" @click="createApp">
                        {{ $t("apps.create") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

.panel {
    padding: 15px;
    width: 100%;
}
.text div {
    margin: 20px 0;
}
.image {
    margin-right: 25px;
}

.text {
    max-width: 550px;
}

.flex-container {
    display: flex;
    justify-content: center;
    width: 100%;

}

.panel {
    flex-direction: row;
    flex: 0 0 auto;
}

ol li {
    margin: 10px 0;
    color: #333;
}

</style>


<script>
export default {
    methods: {
        createApp: function() {
            this.$router.push({
                name: 'AppCreate',
                params: {
                    isOpen: true
                }
            })
        }
    }
};
</script>