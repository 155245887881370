import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_ScheduleOnce = _resolveComponent("ScheduleOnce")!
  const _component_ScheduleRegularInterval = _resolveComponent("ScheduleRegularInterval")!
  const _component_ScheduleEveryDay = _resolveComponent("ScheduleEveryDay")!
  const _component_ScheduleWeekDays = _resolveComponent("ScheduleWeekDays")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Select, {
          id: 'schedule',
          ref: "selectTimeInterval",
          title: _ctx.$t('trigger.schedule.workflow-interval'),
          options: _ctx.selectOptions,
          onOptionSelected: _ctx.setTriggerType
        }, null, 8, ["title", "options", "onOptionSelected"])
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_ScheduleOnce, { onUpdateSchedule: _ctx.update }, null, 8, ["onUpdateSchedule"])
      ], 512), [
        [_vShow, _ctx.trigger.schedule.type === 'once']
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_ScheduleRegularInterval, { onUpdateSchedule: _ctx.update }, null, 8, ["onUpdateSchedule"])
      ], 512), [
        [_vShow, _ctx.trigger.schedule.type === 'regular_interval']
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_ScheduleEveryDay, { onUpdateSchedule: _ctx.update }, null, 8, ["onUpdateSchedule"])
      ], 512), [
        [_vShow, _ctx.trigger.schedule.type === 'every_day']
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_ScheduleWeekDays, { onUpdateSchedule: _ctx.update }, null, 8, ["onUpdateSchedule"])
      ], 512), [
        [_vShow, _ctx.trigger.schedule.type === 'days_of_week']
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-default btn-action",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save()))
      }, "Create schedule")
    ])
  ]))
}