import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "select-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "select" }
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "dropdown-title" }
const _hoisted_6 = ["id"]
const _hoisted_7 = ["data-title", "data-value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.$props.title !== '')
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(this.$props.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        id: this.$props.id + '-drop',
        class: "dropdown",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showOptions($event)))
      }, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(typeof this.$props.selected !== 'undefined' && this.$props.selected !== null ? this.$props.selected.title : ''), 1)
      ], 8, _hoisted_4),
      _createElementVNode("div", {
        id: this.$props.id + '-options',
        class: "options"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("div", {
            "data-title": option.title,
            "data-value": option.id,
            key: option.id,
            class: _normalizeClass(typeof this.$props.selected !== 'undefined' && this.$props.selected.id === option.id ? 'option selected' : 'option'),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSelectedValue($event)))
          }, [
            _createElementVNode("span", null, _toDisplayString(option.title), 1),
            _createTextVNode(" " + _toDisplayString(option.description), 1)
          ], 10, _hoisted_7))
        }), 128))
      ], 8, _hoisted_6)
    ])
  ]))
}