import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "channel-option-parameters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MappingTree = _resolveComponent("MappingTree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.parameters, (param, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("label", null, _toDisplayString(param.name) + " " + _toDisplayString(param.required ? '*' : ''), 1),
        _createVNode(_component_MappingTree, {
          mapping: this.$props.collectedData,
          onPathSelected: _ctx.pathSelected,
          id: 'param_' + param.name,
          visible: false,
          displayButton: true,
          collectionIsSelectable: false,
          leafIsSelectable: true,
          value: param.value,
          onKeyUp: _ctx.keyup
        }, null, 8, ["mapping", "onPathSelected", "id", "value", "onKeyUp"])
      ]))
    }), 128))
  ]))
}