import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "create-new-account",
  id: "create-new-account"
}
const _hoisted_2 = { id: "frm-create-new-account" }
const _hoisted_3 = {
  key: 0,
  id: "authSuccess"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { for: "app-account-name" }
const _hoisted_6 = { class: "flex-group" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "app-list-container" }
const _hoisted_9 = ["onClick", "data-appName", "data-title"]
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 0,
  class: "app-button-container"
}
const _hoisted_12 = {
  key: 2,
  id: "create-new-account-toolbar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("apps.create")), 1)
      ]),
      (this.authSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Auth successfull "))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("apps.appAccountTitle")) + ": ", 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("input", {
            id: "app-account-name",
            onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (this.triggerSave && this.triggerSave(...args))),
            type: "text",
            class: "form-control",
            placeholder: "Give your app account a name"
          }, null, 32),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-default",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setTitle && _ctx.setTitle(...args)))
          }, _toDisplayString(_ctx.$t('main.next')), 1)
        ])
      ]),
      (this.title !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.apps, (app) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: app.id,
                  class: "app-list-item",
                  onClick: ($event: any) => (_ctx.setApp(app)),
                  "data-appName": app.appName,
                  "data-title": app.title
                }, [
                  _createElementVNode("img", {
                    src: app.image
                  }, null, 8, _hoisted_10),
                  _createElementVNode("div", null, _toDisplayString(app.title), 1)
                ], 8, _hoisted_9))
              }), 128))
            ]),
            (typeof this.selectedApp.appName !== 'undefined')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-default",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.configure && _ctx.configure(...args)))
                  }, _toDisplayString(_ctx.$t('apps.configure')), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (this.id !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-default",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateAccount && _ctx.updateAccount(...args)))
            }, _toDisplayString(_ctx.$t('main.save')), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}