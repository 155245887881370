export default class MappingTreeBuilder
{
    mapping: any;

    elementId: string;

    leafClassName = 'tree-branch';

    collectionIsSelectable = true;

    leafIsSelectable = false;

    isOpenState = false;

    prefix = '';

    constructor(mapping: any, elementContainerId: string, leafIsSelectable = true, collectionIsSelectable = false) {
        this.mapping = mapping;
        this.elementId = elementContainerId;
        this.leafIsSelectable = leafIsSelectable;
        this.collectionIsSelectable = collectionIsSelectable;
    }

    create(): void
    {
        for (const i in this.mapping) {
            this.heading(this.mapping[i].source);
            this.build(this.mapping[i].mapping, null, String(i));
        }
    }

    heading(header: string): void
    {
        const heading = document.createElement('div');
        heading.innerHTML = header;
        heading.className = 'tree-source-header';

        (document.getElementById(this.elementId) as HTMLElement).appendChild(heading);
    }

    build(mapping: any, node: HTMLElement | null = null, path: string) {
        for (const i in mapping) {

            const container = this.node(String(i), typeof mapping[i] ==='object', path);

            const parent = node === null
                ? document.getElementById(this.elementId) as HTMLElement
                : node;

            parent.appendChild(container);

            if (typeof mapping[i] ==='object') {
                this.build(
                    mapping[i],
                    container.getElementsByClassName(this.leafClassName)[0] as HTMLElement,
                    path + '.' + i
                )
            }
        }
    }

    node(title: string, isBranch: boolean, path: string) {

        const node = document.createElement('div');
        const caret = document.createElement('div');

        const caretIcon = this.isOpenState ? 'tree-caret-down' : 'tree-caret-right';

        caret.className = 'tree-caret ' + (isBranch ? caretIcon : '');
        caret.onclick = () => {

            if (isBranch) {
                if (caret.className.indexOf('tree-caret-down') !== -1) {
                    caret.className = 'tree-caret tree-caret-right';
                } else {
                    caret.className = 'tree-caret tree-caret-down';
                }
            }

            const branch = ((caret.parentNode as HTMLElement).parentNode as HTMLElement).getElementsByClassName(this.leafClassName)[0] as HTMLElement;

            branch.style.display = branch.style.display !== 'none'
                ? 'none'
                : 'block';
        }

        const checkbox = document.createElement('div');

        path = isBranch ? path : path;

        if ((this.leafIsSelectable && !isBranch) || (this.collectionIsSelectable && isBranch)) {

            checkbox.className = 'tree-checkbox';
            checkbox.setAttribute('data-path', path + '.' + title);
            checkbox.id = String((new Date()).getTime());
            checkbox.onclick = () => {
                this.check(checkbox);
            }
        }

        const link = document.createElement('div');
        link.innerHTML = title;
        link.className = (this.leafIsSelectable && !isBranch) || (this.collectionIsSelectable && isBranch)
            ? 'tree-link'
            : 'tree-link-disabled';

        if ((this.leafIsSelectable && !isBranch) || (this.collectionIsSelectable && isBranch)) {
            link.onclick = () => {
                const checkbox = (link.parentNode as HTMLElement).getElementsByClassName('tree-checkbox')[0] as HTMLElement;
                this.check(checkbox)
            }
        }

        const header = document.createElement('div');
        header.className = 'tree-header';

        header.appendChild(caret);
        header.appendChild(checkbox);
        header.appendChild(link);


        const branch = document.createElement('div');
        branch.className = this.leafClassName;
        branch.style.display = this.isOpenState ? 'block' : 'none';

        node.appendChild(header);
        node.appendChild(branch);

        return node;

    }

    search (query: string): void
    {
        const nodes = document.getElementsByClassName('tree-checkbox');

        if (query.length < 3) {
            return;
        }

        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i] as HTMLElement;
            if (node.getAttribute('data-path')?.toLowerCase().indexOf(query.toLowerCase()) !== -1) {

                while (node.parentNode) {

                    if (node.className === 'tree-branch') {
                        node.style.display = 'block';
                        const caret = (node.parentNode as HTMLElement).getElementsByClassName('tree-caret')[0] as HTMLElement;
                        caret.className = 'tree-caret tree-caret-down'
                    }

                    node = node.parentNode as HTMLElement;
                }
            }
        }
    }

    check(checkbox : HTMLElement) {

        const tree = document.getElementById(this.elementId) as HTMLElement;
        const nodes = tree.getElementsByClassName('tree-checkbox');

        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].getAttribute('data-path') !== checkbox.getAttribute('data-path')) {
                nodes[i].className = 'tree-checkbox';
            }
        }

        checkbox.className = checkbox.className === 'tree-checkbox'
            ? 'tree-checkbox checked'
            : 'tree-checkbox';

        const e = new CustomEvent('onPathSelected', {
            detail: {
                path: checkbox.className === 'tree-checkbox' ? '' : checkbox.getAttribute('data-path'),
                id: this.elementId
            }
        });

        (document.getElementById(this.elementId) as HTMLElement).dispatchEvent(e);
    }
}