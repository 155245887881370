export default class FlowDrag {

    jsContainer: HTMLElement;
    plumbContainer: HTMLElement;

    isMouseDown = false;

    currentMouseX = 0;
    currentMouseY = 0;

    constructor(elementId: string, objectId: string) {

        this.jsContainer = document.getElementById(elementId) as HTMLElement;
        this.plumbContainer = document.getElementById(objectId) as HTMLElement;

        this.jsContainer.onmousedown = (event: any) => {
            this.currentMouseX = Math.abs(this.plumbContainer.offsetLeft - event.clientX);
            this.currentMouseY = Math.abs(this.plumbContainer.offsetTop - event.clientY);
            this.isMouseDown = true;
        }

        this.jsContainer.onmouseup = () => {
            this.isMouseDown = false;
        }

        this.jsContainer.onmousemove = (event: any) => {
            if (this.isMouseDown) {

                let objectX = (event.clientX - Math.abs(this.currentMouseX));

                if (event.clientX < this.plumbContainer.offsetLeft) {
                    objectX = (event.clientX + Math.abs(this.currentMouseX))
                }

                this.plumbContainer.style.left = objectX + 'px';
                this.plumbContainer.style.top = (event.clientY - Math.abs(this.currentMouseY)) + 'px';
            }
        }
    }
}