

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest"

@Options({
    data() {
        return {
            email: ''
        }
    },
    methods: {
        sendLink() {
            ApiRequest(
                'POST',
                '/Account/password/reset/mail',
                {
                    email: this.email
                }
            ).then(() => {
                this.$router.push({name: 'PasswordResetSend'})
            });
        }
    },
    mounted() {
        this.$refs.inputEmail.focus();
    }
})

export default class PasswordReset extends Vue{}

