

import {Options, Vue} from "vue-class-component";

@Options({
    emits: ['toggleDisplayState'],
    props: {
        title: '',
        complete: false,
        open: false
    },
    methods: {
        toggleDisplayState() {
            this.$emit('toggleDisplayState')
        }
    }
})
export default class CreateChannelToolbar extends Vue{}
