export default class Mapping {
    flatten(mapping: any): any {

        const toReturn: { [key: string]: string } = {};

        for (const i in mapping) {
            if (!mapping.hasOwnProperty(i)) continue;

            if ((typeof mapping[i]) == 'object' && mapping[i] !== null) {
                const flatObject = this.flatten(mapping[i]);
                for (const x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) {
                        continue;
                    }

                    toReturn[i + '.' + x] = '';
                }
            } else {
                toReturn[i] = '';
            }
        }

        return toReturn;
    }
    recursiveVars(schema: any): Array<string> {

        const map:any = {};

        if (typeof schema === 'undefined') {
            return [];
        }

        if (typeof schema.type === 'undefined') {
            return [];
        }

        if (schema.type === 'object') {
            for (const i in schema.properties) {

                if (typeof schema.properties[i].type === 'undefined') {
                    continue;
                }

                if (schema.properties[i].type === 'string' || schema.properties[i].type === 'integer') {
                    map[i] = i;
                } else if (schema.properties[i].type === 'array') {
                    map[i + '[]'] = this.recursiveVars(schema.properties[i].items);
                } else {
                    map[i] = this.recursiveVars(schema.properties[i])
                }
            }
        }

        return map;
    }

}
