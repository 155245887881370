

import { Options, Vue } from "vue-class-component";
import TriggerComponent from "@/flowbuilder/Builder/Components/TriggerComponent";

@Options({
    props: {
        index: Number,
        id: String,
        app: null,
        component: TriggerComponent
    },
    emits: ['addComponent','close', 'editComponent'],
    data() {
        return {
        };
    },
    setup() {
        return {}
    },
    methods: {
        edit() {
            this.$emit('editComponent', this.$props.component)
        },
        addComponent() {
            this.$props.flow.splice(
                this.$props.index,
                0,
                {
                    appAccount: {
                        id: '19283737'
                    }
                }
            );
        }
    }
})
export default class Trigger extends Vue {}
