<template>

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css" />
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">

    <div id="container" v-if="userIsAuthenticated()">

        <Menu :user="this.getLocalUser()" :active="activeMenu"></Menu>
        <div class="header">
            {{ this.header }}
        </div>
        <div class="main">
            <router-view />
        </div>
    </div>

    <div v-else>
        <router-view />
    </div>

</template>

<script>

import Menu from "@/components/Menu/Menu.vue";

export default {
    name: 'App',
    components: {Menu},
    props: {
        auth: Number
    },
    data() {
        return {
            header: '',
            subheader: '',
            activeMenu: '',
            user: {},
            isAuthenticated: false
        }
    },
    methods: {
        firstLetter(name) {
            return typeof name === 'undefined'
                ? ''
                : name[0].toUpperCase()
            ;
        },
        logout() {
            this.$store.commit('logout');
            this.$router.push({name: 'Home'})
        },
        getLocalUser() {
            let localUser = localStorage.getItem('user');

            if (localUser !== null) {
                return JSON.parse(localUser);
            }

            return this.logout();
        },
        verifyLocalUser() {
            let localUser = localStorage.getItem('user');

            if (localUser !== null) {
                this.$store.commit('setSignedInUser', JSON.parse(localUser));
            }
        },
        userIsAuthenticated() {
            let signedInUser = this.$store.getters.getSignedInUser;
            return typeof signedInUser.id !== 'undefined';
        }
    },
    beforeMount() {
        this.verifyLocalUser()
    },
    computed: {
        signedInUser() {
            return this.$store.getters.getSignedInUser;
        }
    }
}
</script>