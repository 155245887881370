
import {BrowserJsPlumbInstance} from "@jsplumb/browser-ui";
import ViewComponent from "@/flowbuilder/Builder/ViewComponent";

export default class Methods {

    instance : BrowserJsPlumbInstance;

    constructor(instance: BrowserJsPlumbInstance) {
        this.instance = instance;
    }

    mouse: any = {x: 0, y: 0};

    drawComponent(px: number, py: number, component: ViewComponent) : HTMLElement
    {
        const div:HTMLElement = document.createElement('div');
        div.className = 'component-item ';
        // div.innerHTML = text[0] + text[1] + text[2];
        div.style.left = px + 'px';
        div.style.top = py + 'px';

        // ugly hack but jsplumb refuses to fire click events
        div.onmousedown = (event: MouseEvent) => {
            const target = event.target as HTMLElement;

            if (target.getAttribute('data-trash') === '1') {

                const e = new CustomEvent('removeComponent', {
                    detail: {
                        component: component
                    }
                });
                window.dispatchEvent(e);

                return;
            }

            event = event || window.event; // IE-ism
            this.mouse.x =  event.clientX;
            this.mouse.y =  event.clientY;
        }

        div.onmouseup = (event: MouseEvent) => {
            if (this.mouse.x === event.clientX && this.mouse.y === event.clientY) {
                const e = new CustomEvent('editComponent', {
                    detail: {
                        component: component
                    }
                });
                window.dispatchEvent(e);
            }
        }


        return div;
    }
}
