

import { Options, Vue } from "vue-class-component";
import ChannelComponent from "@/flowbuilder/Builder/Components/ChannelComponent";

@Options({
    components: { },
    props: {
        refs: Array,
        id: Number,
        app: Object,
        component: ChannelComponent
    },
    data() {
        return {
            displayTrashCan: false
        }
    },
    methods: {
        displayTrash() {
            this.displayTrashCan = true;
        },
        hideTrash() {
            this.displayTrashCan = false;
        }
    }
})
export default class Channel extends Vue {}
