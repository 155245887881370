

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import AppAccount from "@/models/AppAccount";
import AppAccountConfig from "@/models/AppAccountConfig";

@Options({
    emits: ['appAccountCreated', 'cancelCreateAppAccount'],
    props: {
        appName: null,
    },
    data() {
        return {
            appAccountName: '',
            authType: String,
            authClient: String,
            app: Object,
            account: Object,
            watcher: null
        }
    },
    methods: {
        test() {

            let data = {
                appName: this.app.appName,
                name: this.appAccountName,
                configuration: [],
            };

            if (this.app.appName === '' || this.appAccountName === '') {
                console.log('appName or appAccountName can not be empty')
                return;
            }

            ApiRequest('POST', '/app/account', data).then((response) => {
                if (typeof response.id === 'undefined') {
                    console.log('could not create app account');
                    return;
                }

                this.account = response;
                this.callGoogle(this.selectedApp);
            });
        },
        callGoogle() {
            ApiRequest(
                'GET', '/google/auth/url?appAccountId=' + this.account.id
            ).then((response) => {

                const opener: Window = window.open(
                    response.url,
                    'targetWindow',
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=800,
                    height=600`
                ) as Window;

                this.watcher = setInterval(() => {
                    if (opener.closed) {
                        try {
                            console.log('JOE',opener.document.documentElement.outerHTML)
                        } catch {}
                        clearInterval(this.watcher);
                        //this.$router.push({name: 'AppOverview'})
                        this.$emit('appAccountCreated')
                    }
                }, 500);
            });
        },
        cancelCreateAccount() {
            this.$emit('cancelCreateAppAccount')
        },
        createAccount() {

            const configuration: Array<any> = [];

            let data = {
                appName: this.app.appName,
                name: (document.getElementById('app-name') as HTMLInputElement).value,
                configuration: configuration
            };

            let vars = document.getElementsByClassName('app-var');

            for (var i = 0; i < vars.length; i++) {
                const input = vars[i] as HTMLInputElement;
                data.configuration.push({ key: input.name, value: input.value});
            }

            ApiRequest('POST', '/app/account', data).then((response) => {

                const configs: Array<AppAccountConfig> = [];

                response.configuration.forEach((config: any) => {
                    configs.push(
                        new AppAccountConfig(config.id, config.key, config.value, config.created)
                    )
                });

                const appAccount: AppAccount = new AppAccount(
                    response.id,
                    response.appName,
                    response.name,
                    response.userId,
                    response.created,
                    configs
                )
                this.$emit('appAccountCreated', appAccount)
            });
        }
    },
    mounted() {

        const appName = this.$props.appName !== null && typeof this.$props.appName !== 'undefined'
            ? this.$props.appName
            : this.$route.path.split('/')[2];

        ApiRequest('GET', '/app?filter=' + appName).then((response) => {
            const app = response[0];
            this.app = app;

            if (typeof app.authType !== 'undefined' && app.authType !== null && typeof Object.keys(app.authType)[0] !== 'undefined') {
                const key = Object.keys(app.authType)[0];
                this.authType = app.authType[key].type;
                this.authClient = app.authType[key]['x-client'];
            }
        });
    }
})

export default class CreateAccount extends Vue{}
