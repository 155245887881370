import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/main.css";
import "./assets/css/nav.css";
import "./assets/css/form.css";
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { createI18n } from 'vue-i18n'
import nl from "../locales/nl.json";
import en from "../locales/en.json";

const i18n = createI18n({
    locale: "en",
    globalInjection: true,
    fallbackLocale: "en",
    messages: { nl, en },
    legacy: false
});

createApp(App)
    .use(store)
    .use(router)
    .use(Vue3Toastify, {
        autoClose: 3000,
    } as ToastContainerOptions)
    .use(i18n)
    .mount("#app");