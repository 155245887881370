

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import Select from "@/components/Flowbuilder/Select.vue";
import CreateAccount from "@/components/Flowbuilder/Sidebar/ChannelOption/CreateAccount.vue";
import App from "@/flowbuilder/Builder/App";
import AppAccount from "@/models/AppAccount";
import AppAccountService from "@/service/AppAccountService";
import ChannelComponent from "@/flowbuilder/Builder/Components/ChannelComponent";

@Options({
    components: {CreateAccount, Select},
    emits: ['appAccountSelected'],
    props: {
        component: null,
        apps: Array
    },
    data() {
        return {
            selectedApp: null,
            appAccounts: [],
            selectAccountOptions: [],
            selectedOption: {},
            appAccountId: '',
            createNewAccount: false,
            appAccount: AppAccount
        }
    },
    methods: {
        cancelCreateAccount() {
            this.setCreateNewAccount(false)
        },
        appAccountCreated(appAccount: AppAccount) {
            this.appAccount = appAccount;
            this.setExistingAccount(appAccount);
            this.setCreateNewAccount(false)
        },
        setApp(appName: string, appImage: string) {

            const app: App = new App(appName, appImage)
            this.selectedApp = app;

            const appAccountService = new AppAccountService();

            ApiRequest('GET', '/app/account?appName=' + app.getName()).then((response) => {
                this.selectAccountOptions = [];

                response.forEach((account: any) => {

                    this.selectAccountOptions.push({
                        id: account.id,
                        title: account.name
                    })

                    this.appAccounts.push(
                        appAccountService.createFromResponse(account)
                    )
                })

                if (response.length > 0) {
                    this.selectedOption = {
                        id: response[0].id,
                        title: response[0].name,
                    }

                    this.setExistingAccount(response[0].id);
                } else {
                    this.selectedOption = {};
                }
            });
        },
        setExistingAccount(appAccountId: string) {
            this.appAccounts.forEach((appAccount: AppAccount) => {
                if (appAccount.getId() === appAccountId) {
                    this.appAccount = appAccount;
                }
            });
        },
        setAppAccount() {
            this.$emit('appAccountSelected', this.appAccount)
        },
        setCreateNewAccount(create: boolean) {
            this.createNewAccount = create;
        }
    },
    mounted() {

        if (typeof this.$props.component !== 'undefined') {

            const channel : ChannelComponent = this.$props.component as ChannelComponent;

            const app: App = channel.getApp();

            this.setApp(
                app.getName(),
                app.getImage()
            );

            this.selectedOption = {
                id: channel.getAppAccount().id,
                title: channel.getAppAccount().name
            }
        }
    }
})

export default class OptionAccount extends  Vue{}
