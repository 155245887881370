

import {Options, Vue} from "vue-class-component";

@Options({
    inheritAttrs: false,
    props: {
        workflow: Object
    },
    methods: {
        getSteps(steps: any) {

            let list: any = [];

            steps.forEach((step: any) => {
                if (step.componentType === 'channel') {
                    list.push(step);
                }
            });

            return list;
        }
    }
})
export default class WorkflowListItem extends Vue{}
