import User from "@/models/User";
import UserToken from "@/models/UserToken";

export default class UserProvider
{
    getUser(): User | null
    {
        const localUser = localStorage.getItem('user');

        return localUser !== null
            ? this.parseUser(localUser)
            : null;
    }

    parseUser(userJsonData: string): User
    {
        const user =JSON.parse(userJsonData);

        return new User(
            user.id,
            new UserToken(
                user.token.token,
                user.token.expiresAt
            ),
            user.email
        )
    }

    isExpired(user: User): boolean
    {
        const currentTimestamp = Math.round(new Date().getTime() / 1000);
        return currentTimestamp >= user.getToken().getExpiresAt();
    }
}
