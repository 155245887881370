

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";

@Options({
    data() {
        return {
            apps: [],
            workflow: null
        }
    },
    methods: {
        getAppImage(appName: string): string
        {
            for (const i in this.apps) {
                if (this.apps[i].appName === appName) {
                    return this.apps[i].image;
                }
            }
            return '';
        },
        formatBytes(bytes: number) {
            if (bytes < 1000) {
                return bytes + 'b';
            }

            if (bytes > 1000) {
                return Math.round(bytes / 1000) + 'kb';
            }
        },
        getBytesSend(run: any) {
            let totalBytesSend = 0;
            run.logs.forEach((log: any) => {
                totalBytesSend += log.bytesSend
            })

            return this.formatBytes(totalBytesSend);
        },
        getBytesReceived(run: any) {
            let totalBytesReceived = 0;
            run.logs.forEach((log: any) => {
                totalBytesReceived += log.bytesReceived
            })

            return this.formatBytes(totalBytesReceived);
        },
        getRequests(run: any) {
            return run.logs.length;
        },
        run() {
            this.$root.ProgressBarStart();

            ApiRequest('POST', '/workflow/' + this.workflow.id + '/start').then (() => {
                this.$root.ProgressBarFinalize();
                this.loaded = true;
            });
        },
        edit() {
            this.$router.push({
                name: 'WorkflowEdit',
                params: {
                    id: this.workflow.id
                }
            })
        }
    },
    created() {
        let parts = document.location.href.split('/');

        ApiRequest('GET', '/app').then(response => {
            this.apps = response;
        })

        ApiRequest('GET', '/workflow/' + parts[parts.length -1]).then ((response) => {
            this.workflow = response;
            this.$root.header = 'Workflow usage: ' + this.workflow?.name;
            this.loaded = true;
        });
    },
    mounted() {
        this.$root.activeMenu = 'workflow-overview';
    }
})

export default class WorkflowView extends Vue{}

