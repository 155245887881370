import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group form-group-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_Select, {
        id: 'schedule_regular_interval_unit',
        ref: "selectTimeInterval",
        title: _ctx.$t('trigger.schedule.interval.every'),
        options: _ctx.intervalUnitOptions,
        onOptionSelected: _ctx.setIntervalAmount
      }, null, 8, ["title", "options", "onOptionSelected"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_Select, {
        id: 'schedule_regular_interval_type',
        ref: "selectTimeInterval",
        title: _ctx.$t('trigger.schedule.interval.time-span'),
        options: _ctx.intervalTypeOptions,
        onOptionSelected: _ctx.setIntervalUnit
      }, null, 8, ["title", "options", "onOptionSelected"])
    ])
  ]))
}