

import {Options, Vue} from "vue-class-component";

@Options({
    props: {
        options: Array,
        label: String,
        id: String,
        selectedOption: null
    },
    emits: ['setSearchOption'],
    methods: {

        textSearch(event: any) {
            const box = (event.target.parentNode) as HTMLElement;
            const options = box.getElementsByClassName('mapping-option');
            const searchText = event.target.value.toLowerCase();
            const searchResultContainer = document.getElementsByClassName('mapping-select-container')[0] as HTMLElement;

            searchResultContainer.style.display = searchText.length > 0 ? 'block' : 'none';

            for (const i in options) {

                const option = options[i] as HTMLElement;

                if (typeof option.style !== 'undefined') {
                    option.style.display = option.innerHTML.toLowerCase().indexOf(searchText) !== -1 || event.target.value === ''
                        ? 'block'
                        : 'none';
                }
            }
        },
        setOption(event: any) {

            const box = (event.target) as HTMLElement;
            const parent = box.parentNode?.parentNode as HTMLElement;

            (parent.getElementsByClassName('form-control')[0] as HTMLInputElement).value = event.target.getAttribute('data-id');

            const searchResultContainer = document.getElementsByClassName('mapping-select-container')[0] as HTMLElement;
            searchResultContainer.style.display = 'none';

            this.$emit('setSearchOption', {
                id: this.$props.id,
                value: event.target.getAttribute('data-id')
            });
        }
    }
})

export default class SearchSelect extends Vue{}
