

import createStore from "@/store";
import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";


@Options({

    data() {
        return {
            accountId: '',
            user: {}
        }
    },
    methods: {
        save() {

            const user = createStore.getters.getSignedInUser;

            ApiRequest('POST', '/account/' + user.id, this.user).then(() => {

                let user = this.$store.getters.getSignedInUser;
                user.firstname = this.user.firstname;

                createStore.commit('setSignedInUser', user);

            });
        }
    },
    beforeMount() {
        this.$root.header = 'Settings';
    },
    mounted() {

        const user = createStore.getters.getSignedInUser;

        ApiRequest('GET', '/account/' + user.id).then((response: any) => {
            this.apps = response;
            this.loaded = true;
            this.user = response;
        });
    }
})

export default class Account extends Vue {}

