import ViewComponent from "@/flowbuilder/Builder/ViewComponent";
import ChannelComponent from "@/flowbuilder/Builder/Components/ChannelComponent";
import TriggerComponent from "@/flowbuilder/Builder/Components/TriggerComponent";
import {ComponentType} from "@/flowbuilder/Builder/Enum/ComponentType";
import App from "@/flowbuilder/Builder/App";
import LoopComponent from "@/flowbuilder/Builder/Components/LoopComponent";
import DecisionComponent from "@/flowbuilder/Builder/Components/DecisionComponent";
import DecisionTrueComponent from "@/flowbuilder/Builder/Components/DecisionTrueComponent";
import DecisionFalseComponent from "@/flowbuilder/Builder/Components/DecisionFalseComponent";

export default class FlowTransformer
{
    apps: any[] = [];

    constructor(apps: any[]) {
        this.apps = apps;
    }

    trigger(workflow: any): ViewComponent
    {

        const trigger: TriggerComponent = new TriggerComponent(
            workflow.triggerType,
            workflow.triggerParameters ?? {}
        );

        return new ViewComponent(
            ComponentType.TRIGGER,
            null,
            trigger
        );
    }

    getApp(action: string): App {

        for (const i in this.apps) {

            for (const e in this.apps[i].actions) {
                if (this.apps[i].actions[e].operationId === action) {
                    return new App(this.apps[i].appName, this.apps[i].image);
                }
            }
        }

        return new App('','');
    }

    transform(flow: any): ViewComponent[] {

        const collection: Array<ViewComponent> = [];

        if (typeof flow === 'undefined' || flow === null) {
            return [];
        }

        for (let i = 0; i < flow.length; i++) {

            if (flow[i].componentType === ComponentType.CHANNEL) {

                const component : ChannelComponent = new ChannelComponent(
                    null,
                    this.getApp(flow[i].action),
                    flow[i].action,
                    flow[i].appAccount,
                    flow[i].mapping,
                    flow[i].parameters,
                    flow[i].componentReferenceId
                );

                const view : ViewComponent = new ViewComponent(
                    ComponentType.CHANNEL,
                    null,
                    component,
                    flow[i].x,
                    flow[i].y,
                );

                collection.push(view);
            }

            if (flow[i].componentType === ComponentType.DECISION_TRUE) {
                const componentTrue: DecisionTrueComponent = new DecisionTrueComponent();
                const view : ViewComponent = new ViewComponent(ComponentType.DECISION_TRUE, null, componentTrue, flow[i].x, flow[i].y, this.transform(flow[i].childNodes))
                collection.push(view);
            }

            if (flow[i].componentType === ComponentType.DECISION_FALSE) {
                const componentFalse: DecisionFalseComponent = new DecisionFalseComponent();
                const view : ViewComponent = new ViewComponent(ComponentType.DECISION_FALSE, null, componentFalse, flow[i].x, flow[i].y, this.transform(flow[i].childNodes))
                collection.push(view)
            }

            if (flow[i].componentType === ComponentType.DECISION) {
                const decision: DecisionComponent = new DecisionComponent(null, flow[i].rules, [], []);
                const view : ViewComponent = new ViewComponent(ComponentType.DECISION, null, decision, flow[i].x, flow[i].y, this.transform(flow[i].childNodes))

                collection.push(view)
            }

            if (flow[i].componentType === ComponentType.LOOP) {
                const component : LoopComponent = new LoopComponent(
                    flow[i].id,
                    flow[i].field,
                    []
                );

                const view : ViewComponent = new ViewComponent(
                    ComponentType.LOOP,
                    null,
                    component,
                    flow[i].x,
                    flow[i].y,
                    this.transform(flow[i].childNodes)
                );

                //view.setId(id)
                collection.push(view)
            }
        }

        return collection;
    }

    clone(path: string[]) : string[]
    {
        return JSON.parse(
            JSON.stringify(path)
        );
    }
}
