import { createStore } from "vuex";

export default createStore({
    modules: {},

    state() {
        return {
            signedInUser: {},
            app: {},
        };
    },
    mutations: {
        setSignedInUser(context: any, user: any) {
            localStorage.setItem('user', JSON.stringify(user));
            context.signedInUser = user;
        },
        setApp(state, app) {
            state.app = app;
        },
        logout(state) {
            state.signedInUser = {};
        },
    },
    getters: {
        getSignedInUser(state: any) {
            return state.signedInUser;
        },
        getApp(state) {
            return state.app;
        }
    },
    actions: {
        logoutSignedInUser(context) {
            localStorage.removeItem('user');
            context.commit("logout", null);
        },
    },
});
