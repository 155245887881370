import AppAccountConfig from "@/models/AppAccountConfig";

export default class AppAccount {
    constructor(
        private id: string,
        private appName: string,
        private name: string,
        private userId: string,
        private created: string,
        private configuration: Array<AppAccountConfig>,
    ) {
    }

    getId(): string
    {
        return this.id;
    }

    getName(): string
    {
        return this.name;
    }

    getConfiguration(): Array<AppAccountConfig>
    {
        return this.configuration;
    }

    getAppName(): string
    {
        return this.appName;
    }

    getUserId(): string
    {
        return this.userId;
    }

    getCreated(): string
    {
        return this.created;
    }
}
