

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";

@Options({
    data() {
        return {
            email: ''
        }
    },
    methods: {
        sendLink() {
            ApiRequest(

                'POST',
                '/Account/password/reset/mail',
                {
                    email: this.email
                }
            );
        }
    },
    emits:["userUpdate"]
})

export default class PasswordResetSend extends Vue{}

