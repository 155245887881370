export default class AppAccountConfig {
    constructor(
        private _id: string,
        private _key: string,
        private _value: string,
        private _created: string
    ) {
    }

    get id(): string {
        return this._id;
    }

    get key(): string {
        return this._key;
    }

    get value(): string {
        return this._value;
    }

    get created(): string {
        return this._created;
    }
}