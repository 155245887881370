import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "account-view" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('user.email')), 1),
        _withDirectives(_createElementVNode("input", {
          readonly: "",
          class: "form-control",
          disabled: "",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.email) = $event)),
          ref: "inputEmail",
          name: "email",
          id: "email"
        }, null, 512), [
          [_vModelText, _ctx.user.email]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('user.firstname')), 1),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.firstname) = $event)),
          name: "firstname",
          id: "firstname"
        }, null, 512), [
          [_vModelText, _ctx.user.firstname]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('user.lastname')), 1),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.lastname) = $event)),
          name: "lastname",
          id: "lastname"
        }, null, 512), [
          [_vModelText, _ctx.user.lastname]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-default",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
        }, _toDisplayString(_ctx.$t('main.save')), 1)
      ])
    ])
  ]))
}