

import {Options, Vue} from "vue-class-component";

@Options({
    components: {Node},
    props: {
        mapping: Array,
        isArray: Boolean,
        path: String,
        depth: Number
    },
    data() {
        return {
            open: true,
            id: 0,
        }
    },
    mounted() {
        this.id = this.generateId()
    },
    methods: {
        generateId(): number
        {
            return (new Date()).getUTCMilliseconds()
        },
        select(index: string) {
            console.log(this.$props.path + '.' + index)
        },
        toggleCheck(event: MouseEvent) {

            const checkbox = (event.target as HTMLElement)?.tagName.toLowerCase() === 'i'
                ? (event.target as HTMLElement).parentElement as HTMLElement
                : event.target as HTMLElement;

            if (checkbox.className.indexOf('checked') === -1) {
                checkbox.className = 'map-checkbox checked'
            } else {
                checkbox.className = 'map-checkbox'
            }
        },
        toggle() {
            this.open = !this.open;
        }
    }
})

export default class Node extends Vue{}
