
import {Options, Vue} from "vue-class-component";

@Options({
    props: ['data'],

    data() {
        return {
            parent: 'tree-bundle',
            incoming:  {}
        }
    },
    methods: {

        build(object: Array<any>, depth = 0, parentNode: HTMLElement | null = null) {
            for (const i in object) {

                const leaf = document.createElement('div') as HTMLElement;

                leaf.id = String((new Date()).getTime());
                leaf.setAttribute('data-depth', String(depth));

                leaf.innerHTML = typeof object[i] === 'object'
                    ? '<i class="fas fa-angle-down"></i>'
                    : '';

                leaf.innerHTML += typeof object[i] === 'object'
                    ? i
                    : i + ': ' + object[i];

                leaf.style.marginLeft = '15px';
                leaf.className = 'leaf ' + (typeof object[i] === 'object' ? 'parent' : '');

                const parent = parentNode === null
                    ? document.getElementById('tree-container') as HTMLElement
                    : parentNode;

                parent.appendChild(leaf);

                if (typeof object[i] === 'object') {
                    this.build(object[i], (depth + 1), leaf);
                }
            }
        },

        createTree(object: Array<any>) {

            const container = document.getElementById('tree-container') as HTMLElement;
            container.innerHTML = '';
            this.build(object);

        }
    }
})

export default class IncomingDataView extends Vue{}
