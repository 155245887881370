

import ApiRequest from "@/api/ApiRequest";
import {Options, Vue} from "vue-class-component";

@Options({
    data() {
        return {
            email: '',
            success: false
        }
    },
    methods: {
        sendLink() {
            ApiRequest(
                'POST',
                '/account/registration/resend',
                {
                    email: this.email
                }
            ).then(() => {
                this.success = true;
            });
        }
    },
    mounted() {
        this.$refs.inputEmail.focus();
    }
})

export default class RegisterResend extends Vue{}

