

import { useRoute } from 'vue-router'
import ApiRequest from "@/api/ApiRequest";
import {Options, Vue} from "vue-class-component";

@Options({
    data() {
        return {
            confirmed: false,
            error: false,
            errorMessage: ''
        }
    },
    mounted() {

        const signature = useRoute().params.signature;

        ApiRequest('POST', '/account/registration/confirm', {
            signature: signature}
        ).then((response) => {
            if (response.error) {
                this.error = true;
                this.errorMessage = response.messages[0];
            } else {
                this.confirmed = true;
            }
        })
    }
})
export default class RegisterConfirm extends Vue {}
