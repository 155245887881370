

import {Options, Vue} from "vue-class-component";

@Options({
    props: {
        user: Object,
        active: String
    },
    methods: {
        logout() {
            this.$store.commit('logout');
            this.$router.push({name: 'Home'})
        },
        upgrade() {
            this.$router.push({name: 'Upgrade'})
        }
    }
})

export default class Menu extends  Vue{}

