import UserToken from "@/models/UserToken";

export default class User {
    constructor(
        private id: string,
        private token: UserToken,
        private email: string
    ) {
    }

    getId(): string
    {
        return this.id;
    }

    getToken(): UserToken
    {
        return this.token;
    }
}
