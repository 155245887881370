

import {Options, Vue} from "vue-class-component";
import Select from "../../Select.vue";
import ScheduleEveryDay from "./types/ScheduleEveryDay.vue";
import ScheduleOnce from "./types/ScheduleOnce.vue";
import ScheduleRegularInterval from "./types/ScheduleRegularInterval.vue";
import ScheduleWeekDays from "./types/ScheduleWeekDays.vue";

@Options({
    props: {
        component: Object
    },
    components: {
        Select,
        ScheduleWeekDays,
        ScheduleEveryDay,
        ScheduleRegularInterval,
        ScheduleOnce
    },
    emits: ['triggerUpdate'],
    data() {
        return {
            trigger: {
                complete: false,
                request: null,
                type: 'schedule',
                schedule: {
                    type: ''
                }
            },
            selectedOption: null,
            selectOptions: [
                {
                    id: 'once',
                    title: 'once',
                    description: 'Triggers only one time, choose date'
                },
                {
                    id: 'regular_interval',
                    title: 'At regular interval',
                    description: 'Triggers on selected intervals'
                },
                {
                    id: 'every_day',
                    title: 'Every day',
                    description: 'Triggers every day'
                },
                {
                    id: 'days_of_week',
                    title: 'Days of the week',
                    description: 'Triggers every week'
                },
                {
                    id: 'days_of_month',
                    title: 'Dates of the month',
                    description: 'Triggers on selected days of the month'
                }
            ]
        }
    },
    methods: {
        setTriggerType(triggerType: any) {
            this.trigger.schedule.type = triggerType;
        },
        update(schedule: any) {
            this.trigger.schedule = schedule;
        },
        save() {
            this.$emit('triggerUpdate', this.trigger)
        },
        back() {
            this.trigger.schedule.type = ''
        }
    }
})

export default class Schedule extends Vue{}
