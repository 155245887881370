

import {Options, Vue} from "vue-class-component";
import Node from "@/components/Flowbuilder/MappingTree/Node.vue";
import MappingTreeBuilder from "@/flowbuilder/Builder/MappingTree/MappingTreeBuilder";

@Options({
    components: {Node},
    props: {
        mapping: Array,
        id: String,
        visible: Boolean,
        displayButton: Boolean,
        leafIsSelectable: Boolean,
        collectionIsSelectable: Boolean,
        value: String
    },
    emits: ['pathSelected', 'keyUp'],
    data() {
        return {
            listeners: [],
            tree: Object,
            visible: Boolean,
            value: ''
        }
    },
    methods: {
        search(event: MouseEvent) {
            this.tree.search((event.target as HTMLInputElement).value)
            this.$emit('keyUp', (event.target as HTMLInputElement).value, this.$props.id)
        },
        toggleVisibility() {
            this.visible = !this.visible;
        }
    },
    mounted() {

        this.value = this.$props.value;
        this.visible = this.$props.visible;
        this.listeners.onPathSelected = (e: any) => {

            this.value = e.detail.path;
            const searchBox = (document.getElementById(this.$props.id)?.parentNode as HTMLElement)
                .getElementsByClassName('search-box')[0] as HTMLInputElement;

            searchBox.value = e.detail.path;
            this.$emit('pathSelected', e.detail.path, e.detail.id)
        };

        (document.getElementById(this.$props.id) as HTMLElement)
            .addEventListener('onPathSelected', this.listeners.onPathSelected)

        this.tree = new MappingTreeBuilder(
            this.$props.mapping,
            this.$props.id,
            this.$props.leafIsSelectable,
            this.$props.collectionIsSelectable
        )
        this.tree.create()
    },
    beforeUnmount() {
        (document.getElementById(this.$props.id) as HTMLElement)
            .removeEventListener('onPathSelected', this.listeners.onPathSelected)
    }
})


export default class MappingTree extends Vue{}
