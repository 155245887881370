

import WorkflowListItem from "@/components/Workflow/WorkflowListItem.vue";
import ApiRequest from "@/api/ApiRequest";
import WorkflowEmpty from "@/components/Workflow/WorkflowEmpty.vue";
import Modal from "@/components/Modal/Modal.vue";
import {Options, Vue} from "vue-class-component";
import {toast} from "vue3-toastify";

@Options({
    components: {
        Modal,
        WorkflowEmpty,
        WorkflowListItem
    },
    data() {
        return {
            apps: [],
            workflows: [],
            workflowsLoaded: false,
            modalCreateFlow: false,
            modalRemoveFlow: false,
            workflowName: '',
            removeWorkflowName: '',
            removeWorkflowId: ''
        }
    },
    methods: {
        getAppImage(appName: string): string
        {
            for (const i in this.apps) {
                if (this.apps[i].appName === appName) {
                    return this.apps[i].image;
                }
            }
            return '';
        },
        closeRemoveModal() {
            this.modalRemoveFlow = false;
        },
        remove(id: string) {
            this.removeWorkflowId = id;
            for (const i in this.workflows) {
                if (id === this.workflows[i].id) {
                    this.removeWorkflowName = this.workflows[i].name
                }
            }
            this.modalRemoveFlow = true;
        },
        removeWorkflow(): void
        {

            ApiRequest('DELETE' ,'/workflow/' + this.removeWorkflowId).then(() => {
                this.modalRemoveFlow = false;
                ApiRequest('GET' ,'/workflow').then(response => {
                    this.workflows = response;
                    toast.success('Workflow successfully removed')
                });
            });
        },
        getSteps(steps: any) {

            let list: any = [];

            steps.forEach((step: any) => {
                if (step.componentType === 'channel') {
                    list.push(step);
                }
            });

            return list;
        },
        openModal() {
            this.modalCreateFlow = true;
        },
        closeModal() {
            this.modalCreateFlow = false;
        },
        fetchWorkflows() {

            ApiRequest('GET', '/app').then(response => {
                this.apps = response;
            })

            ApiRequest('GET' ,'/workflow').then(response => {
                this.workflows = response;
                this.workflowsLoaded = true;
            });
        },
        viewWorkflow(workflow: any) {
            this.$router.push({
                name: 'WorkflowView',
                params: {
                    id: workflow.id
                }
            })
        },
        create() {
            if (this.workflowName.trim() === '') {
                console.log('Workflow name can not be empty');
                return;
            }

            ApiRequest('POST',  '/workflow', {
                "name": this.workflowName,
                "published": false,
                "steps": []

            }).then (() => {
                this.fetchWorkflows();
                this.modalCreateFlow = false;
            })
            return false;
        }
    },
    beforeMount() {
        this.$root.header = 'Workflow overview'
        this.$root.activeMenu = 'workflow-overview'
        this.fetchWorkflows();
    }
})
export default class WorkflowOverview extends Vue{}
