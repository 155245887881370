
import {Options, Vue} from "vue-class-component";

@Options({
    emits: ['openModal'],
    methods: {
        openModal() {
            this.$emit('createWorkflow')
        }
    }
})
export default class WorkflowEmpty extends Vue{}
