

import {Options, Vue} from "vue-class-component";
import Flow from "@/components/Flow.vue";
import ApiRequest from "@/api/ApiRequest";

@Options({
    computed: {
        loaded() {
            return this.workflow !== null && this.apps !== null
        }
    },
    data() {
        return {
            workflow: null,
            apps: null,

        }
    },
    components: {Flow},
    mounted() {
        let parts = document.location.href.split('/');

        ApiRequest('GET', '/workflow/' + parts[parts.length -2]).then ((response) => {
            this.workflow = response;
        });

        ApiRequest('GET', '/app').then ((response) => {
            this.apps = response;
        });
    }
})
export default class Workflow extends Vue {}
