

import {Options, Vue} from "vue-class-component";
import VueDatePicker from "@vuepic/vue-datepicker";
import DateFormat from "@/flowbuilder/Date/DateFormat";

@Options({
    components: {VueDatePicker},
    emits: ['updateSchedule'],
    data() {
        return {
            schedule: {
                type: 'every_day',
                time: ''
            },
            langObject: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: false,
            },
            langString: 'nl-NL',
            date: null,
            time: null,
        }
    },
    methods: {
        handleTime(time: any): void {
            this.schedule.time = (new DateFormat()).timeToString(time);
            this.$emit('updateSchedule', this.schedule)
        }
    }
})

export default class ScheduleEveryDay extends Vue{}

