
import ViewComponent from "@/flowbuilder/Builder/ViewComponent";

export default class Plus {

    mouse: any = {x: 0, y: 0};

    label = '';

    py = 0;
    px = 0;

    collection: ViewComponent[] = [];
    parentComponent: ViewComponent | null = null;

    constructor(collection: ViewComponent[], px: number, py: number, label: string | null, parentComponent: ViewComponent | null) {

        this.collection = collection;
        this.parentComponent = parentComponent;
        this.px = px;
        this.py = py;
        this.label = label ?? '';

        if (collection.length === 0 || (collection.length > 0 && collection[collection.length -1].getType() !== 'decision')) {
            if (collection.length > 0) {
                this.py += 100;
            }
        }
    }

    create(): HTMLElement
    {
        const div:HTMLElement = document.createElement('div');
        div.setAttribute('component-type', 'plus');
        div.className = 'component-item'
        div.innerHTML = '<div><i class="fas fa-plus"></i></div>';
        div.style.left = this.px + 'px';
        div.style.top = this.py + 'px';
        div.setAttribute(
            'component-type',
            'plus'
        );

        div.setAttribute('component-label', this.label);

        // ugly hack but jsplumb refuses to fire click events
        div.onmousedown = (event: MouseEvent) => {
            event = event || window.event; // IE-ism
            this.mouse.x =  event.clientX;
            this.mouse.y =  event.clientY;
        }

        div.onmouseup = (event: MouseEvent) => {
            if (this.mouse.x === event.clientX && this.mouse.y === event.clientY) {
                const e = new CustomEvent(
                    'createComponent', {
                        detail: {
                            parentComponent: this.parentComponent,
                            currentCollection: this.collection
                        }
                    });
                window.dispatchEvent(e);
            }
        }

        return div;
    }
}
