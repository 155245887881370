

import ApiRequest from "@/api/ApiRequest";
import AppsEmpty from "@/components/App/AppsEmpty.vue";
import {Options, Vue} from "vue-class-component";

@Options({
    components: {
        AppsEmpty
    },
    data() {
        return {
            appsLoaded: false,
            apps: [],
            showInactive: false,
            searchText: ''
        }
    },
    methods: {
        searchApps() {
            const apps = document.getElementsByClassName('searchable-text');

            for (const i in apps) {

                const parent = apps[i].parentNode?.parentNode as HTMLElement;

                if (typeof parent === 'undefined') {
                    continue;
                }

                const accounts: number = parseInt(parent.getAttribute('data-accounts') ?? '');

                if (accounts === 0 && !this.showInactive) {
                    continue;
                }

                if (this.searchText !== '' && typeof apps[i].innerHTML !== 'undefined' && apps[i].innerHTML.toLowerCase().indexOf(this.searchText) === -1) {
                    if (typeof apps[i].parentNode !== 'undefined') {
                        parent.style.display = 'none';
                    }
                } else {
                    if (typeof apps[i].parentNode !== 'undefined') {
                        parent.style.display = 'table';
                    }
                }
            }
        },
        toggleShowInactive() {
            this.showInactive = !this.showInactive
        },
        showApps() {
            ApiRequest('GET' ,'/app/account/group?active=1').then(response => {
                this.apps = response;
                this.appsLoaded = true;
            });
        }
    },
    beforeMount() {
        this.$root.header = 'Connected apps';
        this.$root.activeMenu = 'connected-apps'
        this.showApps()
    }
})

export default class AppOverview extends Vue{}

