
import Methods from "@/components/test/methods";
import {BrowserJsPlumbInstance} from "@jsplumb/browser-ui";
import {Vue} from "vue-class-component";
import ComponentViewFactory from "@/flowbuilder/Factory/ComponentViewFactory";
import {App, createApp} from "vue";
import FlowBuilder from "@/flowbuilder/Builder/FlowBuilder";
import { createI18n } from 'vue-i18n'
import nl from "../../../../locales/nl.json";
import en from "../../../../locales/en.json";
import ViewComponent from "@/flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/flowbuilder/Builder/Enum/ComponentType";
import Mapping from "@/flowbuilder/Mapping/Mapping";
import ChannelComponent from "@/flowbuilder/Builder/Components/ChannelComponent";
import TriggerComponent from "@/flowbuilder/Builder/Components/TriggerComponent";

export default class ContainerHelper
{
    instance :BrowserJsPlumbInstance;

    constructor(instance :BrowserJsPlumbInstance) {
        this.instance = instance;
    }

    createContainer(component: ViewComponent, label: string): HTMLElement
    {
        const helper = new Methods(this.instance);

        const container: HTMLElement = helper.drawComponent(
            component.getPositionX(),
            component.getPositionY(),
            component
        );

        const id: number = Math.floor(Math.random() * (99999 - 10000 + 1) + 99999);

        container.setAttribute(
            'component-type',
            component.getType()
        );

        container.setAttribute(
            'component-id',
            String(id)
        );

        container.setAttribute(
            'component-label',
            label
        );

        return container;
    }

    createView(component: ViewComponent): App {

        const factory: ComponentViewFactory = new ComponentViewFactory();

        const view : Vue = factory.create(
            component.getType()
        )

        const i18n = createI18n({
            locale: "en",
            globalInjection: true,
            fallbackLocale: "en",
            messages: { nl, en },
            legacy: false
        });

        return createApp({ extends: view }, {
            id: 'ref',
            component: component.getComponent()
        }).use(i18n);
    }

    calculateOffsetY(type: string, startOffsetY: number): number
    {
        let nextOffsetY: number = startOffsetY;

        switch(type) {
        case ComponentType.DECISION_FALSE:
            nextOffsetY -= 0;
            break;
        case ComponentType.DECISION_TRUE:
            nextOffsetY += 80;
            break;
        default:
            nextOffsetY += FlowBuilder.DEFAULT_DISTANCE_Y;
            break;
        }

        return nextOffsetY;
    }

    calculateOffsetX(type: string, startOffsetX: number): number
    {
        let nextOffsetX: number = startOffsetX;

        switch(type) {
        case ComponentType.DECISION_FALSE:
            nextOffsetX -= FlowBuilder.DEFAULT_DISTANCE_DECISION_FALSE_X;
            break;
        case ComponentType.DECISION_TRUE:
            nextOffsetX += FlowBuilder.DEFAULT_DISTANCE_DECISION_TRUE_X;
            break;

        }

        return nextOffsetX;
    }

    remove(view: ViewComponent, collection : ViewComponent[]): ViewComponent[]
    {
        let add = true;
        const n : ViewComponent[] = [];

        for (let e = 0; e < collection.length; e++) {

            const i = e;

            if (collection[i].getId() === view.getId()) {
                add = false;
            }

            if (add) {
                n.push(collection[i]);
            }

            if (typeof collection[i] !== 'undefined' && collection[i].getChildNodes().length > 0) {
                collection[i].setChildNodes(
                    this.remove(view, collection[i].getChildNodes())
                );
            }
        }

        return n;
    }

    translate(apps: any[], appName: string, appAction: string): any
    {
        for (const i in apps) {
            if (apps[i].appName === appName) {
                for (const e in apps[i].actions) {
                    if (apps[i].actions[e].operationId === appAction) {


                        if (typeof apps[i].actions[e].responses === 'undefined' || apps[i].actions[e].responses.length === 0) {
                            console.log('no schema present for ' + appAction)
                            return null;
                        }

                        const response: any = apps[i].actions[e].responses;
                        const responseStatusCodeKey: string[] = Object.keys(response);
                        const responseContentTypeKey: string[] = Object.keys(response[responseStatusCodeKey[0]].content);
                        const mapping: Mapping = new Mapping()

                        return mapping.recursiveVars(
                            response[responseStatusCodeKey[0]].content[responseContentTypeKey[0]].schema
                        );
                    }
                }
            }
        }
    }

    mappingObjects(path: string[], collection : ViewComponent[], apps: any[], action: string): any[]
    {
        const mapped: any[] = [] ;
        let tmp : ViewComponent | null;

        const pathLength = action === 'create' ? path.length : path.length -1;
        for (let i = 0; i < pathLength; i++) {

            tmp = this.findView(path[i], collection);

            if (tmp !== null && tmp.getType() === ComponentType.CHANNEL) {

                const channel :ChannelComponent = tmp.getComponent() as ChannelComponent;

                mapped.push({
                    source: channel.getApp().getName(),
                    mapping:
                        this.translate(
                            apps,
                            channel.getApp().getName(),
                            channel.getAction()
                        )
                })
            }
        }

        return mapped;
    }

    findView(path: string, collection: ViewComponent[]): any
    {
        for (const i in collection) {
            if (collection[i].getId() === path) {
                return collection[i];
            }

            if (collection[i].getChildNodes().length > 0) {
                this.findView(path, collection[i].getChildNodes());
            }
        }

        return null;
    }

    mapping(path: string[], collection : ViewComponent[], apps: any[], action: string): any[]
    {
        const map = [];

        const trigger : TriggerComponent = collection[0].getComponent() as TriggerComponent;

        const t = trigger.getType() === 'webhook'
            ? trigger.getParameters().request
            : [];

        map.push({source: 'webhook', mapping : t});

        return map.concat(
            this.mappingObjects(path, collection, apps, action)
        )
    }
}