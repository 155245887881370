export default class DateFormat {
    dateToString(date: Date): string {
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return date.getFullYear()
            + '-' + (month < 10 ? '0' + month : month)
            + '-' + (day < 10 ? '0' + day : day);
    }

    timeToString(time: any) : string {
        const hours = time.hours < 10 ? '0' + time.hours : time.hours;
        const minutes = time.minutes < 10 ? '0' + time.minutes : time.minutes;
        const seconds = time.seconds < 10 ? '0' + time.seconds : time.seconds;
        return hours + ':' + minutes + ':' + seconds
    }
}