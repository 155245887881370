import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "workflow-list-item" }
const _hoisted_2 = { class: "workflow-item" }
const _hoisted_3 = { class: "workflow-title" }
const _hoisted_4 = { class: "flow-icons" }
const _hoisted_5 = { class: "workflow-item-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.workflow.name), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getSteps(this.$props.workflow.steps), (step, index) => {
          return (_openBlock(), _createElementBlock("div", {
            style: _normalizeStyle('background-image:url(' + step.appImage + ');'),
            key: index
          }, "   ", 4))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, " Published " + _toDisplayString(_ctx.workflow.created), 1)
  ]))
}