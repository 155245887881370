export default class Component
{
    positionX = 0;
    positionY = 0;
    order = 0;
    componentReferenceId = '';

    constructor(componentReferenceId = '') {
        this.componentReferenceId = componentReferenceId;
    }

    setComponentReferenceId(reference: string): void
    {
        this.componentReferenceId = reference;
    }

    getComponentReferenceId(): string
    {
        return this.componentReferenceId;
    }
}